import React from "react"
import "./image_overlay.scss"

const registerEscapeHandler = onClose =>
  (document.onkeydown = ({ key, keyCode }) =>
    (["Esc", "Escape"].includes(key) || keyCode === 27) && onClose())

// www.w3schools.com / howto / howto_js_lightbox.asp
const ImageOverlay = ({ image, onClose }) => {
  registerEscapeHandler(onClose)
  return (
    <div
      className="image-overlay"
      onClick={onClose}
      onKeyPress={onClose}
      role="button"
      tabindex="0"
    >
      <div className="modal">
        <span
          className="close cursor"
          onClick={onClose}
          onKeyPress={onClose}
          role="button"
          tabindex="0"
        >
          &times;
        </span>
        <div className="modal-content">
          <img
            alt="Funny religion-related meme"
            className="full-width"
            src={image}
          />
        </div>
      </div>
    </div>
  )
}

export default ImageOverlay
