import React, { useState } from "react"
import { Link } from "gatsby"
// import Video from "../../components/video"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Routes from "../../routes"
import { useImpression } from "../../analytics/utils"
import LastSupperMeme from "../../images/memes/jesus-last-supper-meme.png"
import ImageOverlay from "../../components/image_overlay"
import AtheistNote from "../../images/memes/what-its-like-to-be-atheist.png"
import BillBurrRant from "../../images/memes/bill-burr-church-rant.png"
import "./memes.scss"

const importAll = r => {
  const images = {}
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item)
  })
  return images
}

const images = importAll(
  require.context("../../images/memes", false, /\.(png|jpe?g|svg)$/)
)

const imagesAsArrays = Object.entries(images)

const memeAltText = key =>
  `${key
    .split(".")[0]
    .split("-")
    .join(" ")} funny meme`

const shuffle = array => array.sort(() => Math.random() - 0.5)

const shuffledMemes = shuffle(imagesAsArrays)

const Memes = ({ location }) => {
  useImpression("Memes")
  const [selectedImage, setSelectedImage] = useState(null)
  const firstHalfImages = shuffledMemes.slice(0, shuffledMemes.length / 2)
  const secondHalfImages = shuffledMemes.slice(shuffledMemes.length / 2)
  const handleMemeClick = e => setSelectedImage(e.target.src)
  return (
    <Layout className="memes" location={location}>
      <Seo
        title="Best Jesus and Religious Memes and Jokes"
        description="Jesus memes are a guilty pleasure of us all. Funny Jesus memes and jokes to brighten up your day. The best memes about Jesus, religios ideas, Christianity and more."
      />
      <h1>Jesus Christ Memes and Jokes</h1>
      {selectedImage && (
        <ImageOverlay
          image={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}
      {/* <div className="d-flex flex-column justify-center align-center">
        <Video controls url="https://www.youtube.com/watch?v=Tr0UpQXYkGs" />
        <br />
        <p className="d-flex align-center justify-center">
          This one just hurts to watch. Jack Schaap does the weirdest thing of
          all time demonstrating how to polish a shaft.{" "}
          <span aria-label="facepalm" role="img">
            🤦‍♂️
          </span>
        </p>
      </div> */}
      <div className="meme-grid padding-top-10px">
        {[firstHalfImages, secondHalfImages].map((imagesForColumn, index) => (
          <div className="column" key={`grid-col-${index}`}>
            {index === 0 && [
              <input
                alt="Jesus and his disciples at the Last Supper"
                key="Jesus and his disciples at the Last Supper"
                onClick={handleMemeClick}
                onKeyPress={handleMemeClick}
                src={LastSupperMeme}
                type="image"
              />,
              <input
                alt="Explaining how atheism feels similar to a majority of people believing in dragons."
                key="Explaining how atheism feels similar to a majority of people believing in dragons."
                onClick={handleMemeClick}
                onKeyPress={handleMemeClick}
                src={AtheistNote}
                type="image"
              />,
              <input
                alt="Bill Burr roasts Priests as regular people, with equal information as the rest of us. So what do they know about truth?"
                key="Bill Burr roasts Priests as regular people, with equal information as the rest of us. So what do they know about truth?"
                onClick={handleMemeClick}
                onKeyPress={handleMemeClick}
                src={BillBurrRant}
                type="image"
              />,
            ]}
            {imagesForColumn.map(([name, imagePath]) => (
              <input
                alt={memeAltText(name)}
                key={name}
                onClick={handleMemeClick}
                onKeyPress={handleMemeClick}
                src={imagePath}
                type="image"
              />
            ))}
          </div>
        ))}
      </div>

      <p>
        Well here's a bunch of funny, weird, dumb memes and videos about God,
        Jesus, and religion. But don't forget that they're just jokes, so no
        hard feelings.
      </p>
      <p>
        Memes can actually tell us quite a lot about some stuff. They point out
        quirky things when some ideas are only thought about independently. Like
        Jesus walking on water, and Becky, walking on water bottles. Religions
        can can seem funny pretty quickly. Enjoy these Christian, Jesus, and
        religious memes.
      </p>
      <p>
        Jesus is the primary figure of the Bible's New Testament. The book tells
        us about his teachings and about supposed miracles, of which there is
        little tangible evidence to support. These were some of our favorite
        memes that people have made for him. Hopefully neither God, the Holy
        Spirit, Jesus, nor any religious people take offense to this, they're
        presented in good fun as educational, and comical.
      </p>
      <hr />
      <p>
        Be sure to check out our <Link to={Routes.BIBLE}>Bible</Link> page to
        learn more.
      </p>
    </Layout>
  )
}

export default Memes
